@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'HelveticaNeue', 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.mono {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.settings {
  overflow-x: hidden;
  overflow-y: auto;
  padding: 1.5rem;
  background: #25282a;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
}
